/* eslint-disable jsx-a11y/no-autofocus */
import Divider from '@components/Divider';
import GoogleButton from '@components/GoogleButton';
import PageMeta from '@components/PageMeta';
import TextField from '@components/TextField';
import Translate from '@components/Translate';
import { useAuth } from '@context/AuthContext';
import { AuthType, User } from '@helpers/AuthClient';
import getAuthPageUrl from '@helpers/getAuthPageUrl';
import isBrowser from '@helpers/isBrowser';
import useTranslation from '@helpers/useTranslation';
import AuthTemplate from '@templates/AuthTemplate';
import { Link, navigate } from 'gatsby';
import React, { FC, useState } from 'react';
import { Button, Form, Message } from 'react-bulma-components';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

interface FormValues {
  email: string;
  password: string;
}

interface LoginViewProps {
  authType: AuthType;
  defaultValues?: Partial<FormValues>;
  message?: string;
  onSuccess?(user: User): void;
}

const LoginView: FC<LoginViewProps> = ({ authType, defaultValues, message, onSuccess }) => {
  const { t } = useTranslation(['auth', 'validation', 'pages']);

  const { authClient } = useAuth();

  const [isLoading, setIsLoading] = useState(false);

  const formProps = useForm<FormValues>({ defaultValues });

  const handleFormSubmit: SubmitHandler<FormValues> = ({ email, password }) => {
    setIsLoading(true);

    authClient
      .login(email, password)
      .then(async user => {
        if (!user.emailVerified) {
          return navigate(verifyEmailUrl);
        }

        onSuccess?.(user);
      })
      .catch(error => {
        let errorType;
        if (error.code === 'auth/user-not-found' || error.code === 'auth/wrong-password') {
          errorType = 'badUsernamePassword';
        } else if (error.code === 'auth/user-disabled') {
          errorType = 'disabledUser';
        }

        if (errorType) {
          formProps.setError('email', { message: t(`validation:${errorType}`) });

          formProps.setValue('password', '');
          formProps.setFocus('password');
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const email = formProps.watch('email');

  const forgotPasswordUrl = getAuthPageUrl('forgotPassword', authType);
  const registerUrl = getAuthPageUrl('register', authType);
  const verifyEmailUrl = getAuthPageUrl('verifyEmail', authType);

  const searchParams = isBrowser ? new URLSearchParams(window.location.search) : null;
  const continueTo = searchParams?.get('continueTo') ?? null;
  let googleLoginUrl = getAuthPageUrl('googleLogin', authType);
  if (continueTo) {
    googleLoginUrl += `?continueTo=${continueTo}`;
  }

  return (
    <AuthTemplate>
      <PageMeta description={t('pages:logInDescription')} title={t('pages:logIn')} />
      <AuthTemplate.Content>
        {message && (
          <Message color="success">
            <Message.Body>{message}</Message.Body>
          </Message>
        )}
        <FormProvider {...formProps}>
          <form onSubmit={formProps.handleSubmit(handleFormSubmit)}>
            <GoogleButton action="login" href={googleLoginUrl} />
            <Divider>{t('or')}</Divider>
            <TextField<FormValues>
              autoComplete="username"
              autoFocus={!email}
              id="email"
              label={t('common:email')}
              name="email"
              type="email"
              validation={{ required: t('validation:required', { field: t('common:email') }) }}
            />
            <TextField<FormValues>
              autoComplete="password"
              autoFocus={!!email}
              id="password"
              label={t('password')}
              name="password"
              type="password"
              validation={{ required: t('validation:required', { field: t('password') }) }}
            />
            <Form.Field alignItems="center" kind="group">
              <Form.Control>
                <Button color="primary" loading={isLoading}>
                  {t('login')}
                </Button>
              </Form.Control>
              <Link to={forgotPasswordUrl} state={{ email }}>
                {t('forgotPassword')}
              </Link>
            </Form.Field>
          </form>
        </FormProvider>
        <AuthTemplate.Footer>
          <Translate
            components={{ signUp: <Link to={registerUrl} /> }}
            message={t('haventRegistered')}
          />
        </AuthTemplate.Footer>
      </AuthTemplate.Content>
    </AuthTemplate>
  );
};

export default LoginView;
