import { AuthType, User } from '@helpers/AuthClient';
import useSendToken from '@helpers/useSendToken';
import LoginView from '@views/LoginView';
import TransferTokenView from '@views/TransferTokenView';
import { PageProps } from 'gatsby';
import React, { FC } from 'react';
import { Helmet } from 'react-helmet';

interface LocationState {
  email?: string;
  message?: string;
}

const LoginAppPage: FC<PageProps<null, null, LocationState | null>> = ({ location: { state } }) => {
  const { sendToken, state: sendTokenState, token } = useSendToken();

  const handleLoginSuccess = async (user: User) => {
    sendToken(user);
  };

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      {sendTokenState === 'idle' ? (
        <LoginView
          authType={AuthType.App}
          defaultValues={{ email: state?.email }}
          message={state?.message}
          onSuccess={handleLoginSuccess}
        />
      ) : (
        <TransferTokenView result={sendTokenState} token={token} />
      )}
    </>
  );
};

export default LoginAppPage;
